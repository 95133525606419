var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"width":600,"scroll":"keep","on-cancel":function () {
        _vm.open = false;
        this$1.$parent.close();
      }},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('fieldset',[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Prospect Call Detail # "+_vm._s(_vm.prospectCall.prospect_call_id)+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control is-expanded"},[_c('b-field',{attrs:{"label":"Dialed Number","label-position":"on-border"}},[_c('b-input',{attrs:{"placeholder":"Dialed Number","type":"tel","readonly":""},model:{value:(_vm.prospectCall.number_dialed),callback:function ($$v) {_vm.$set(_vm.prospectCall, "number_dialed", $$v)},expression:"prospectCall.number_dialed"}})],1)],1),_c('p',{staticClass:"control is-expanded"},[_c('b-field',{attrs:{"label":"Call Duration","label-position":"on-border"}},[_c('b-input',{attrs:{"placeholder":"Call Duration","expanded":"","readonly":""},model:{value:(_vm.prospectCall.duration),callback:function ($$v) {_vm.$set(_vm.prospectCall, "duration", $$v)},expression:"prospectCall.duration"}})],1)],1)]),_c('b-field',{attrs:{"label":"Prospect Call Remarks","label-position":"on-border"}},[_c('b-input',{attrs:{"placeholder":"Call remarks","type":"textarea","readonly":""},model:{value:(_vm.prospectCall.remarks),callback:function ($$v) {_vm.$set(_vm.prospectCall, "remarks", $$v)},expression:"prospectCall.remarks"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"field is-grouped is-grouped-right"},[_c('p',{staticClass:"control"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function () {
                    _vm.open = false;
                    this$1.$parent.close();
                  }}},[_vm._v(" Close ")])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }