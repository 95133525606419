import Util from '@/mixins/Util'
import { MeetingType } from '@/types/Meeting'
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import network from '@/services/Network'

const MeetingModule = namespace('MeetingModule')

@Component({
  name: 'MeetingDetails'
})
export default class MeetingDetails extends Mixins(Util) {
    @Prop({ required: true }) prospectId!: number;
    @Prop({ required: true }) meeting!: MeetingType.Content;

    @MeetingModule.Action
    private updateAMeeting!: (args: { prospectId: number, meetingId: number, meeting: MeetingType.Content }) => Promise<boolean>;

    private open = true;
    private isMeetingBeingUpdated = false;
    private meetingCopy!: MeetingType.Content;

    private meetingDateCopy: Date = new Date();
    private isMeetingCompleted = false;

    private isFetchingEmployeeListComplete = false;
    private dataPlaceholderForEmployeeList = new Array<{ employee_id: number, employee_name: string }>();
    private meetingAttendedBy: { employee_id: number, employee_name: string } = {} as any;

    created () {
      this.meetingCopy = { ...this.meeting }
      this.isMeetingCompleted = this.meetingCopy.meeting_completed

      // todo make this into a util function
      //* Concat the time part of Date object and parse it to get the full date and time
      const date = new Date(this.meetingCopy.meeting_date)
      const completeDate = date.toUTCString().replace(/\d{2}:\d{2}:\d{2}/gm, this.meetingCopy.meeting_time as string)

      this.meetingDateCopy = new Date(Date.parse(completeDate))
      this.meetingDateCopy.setTime(this.meetingDateCopy.getTime() + this.meetingDateCopy.getTimezoneOffset() * 60000)

      this.meetingAttendedBy = {
        employee_id: this.meeting.employee.employee_id,
        employee_name: this.meeting.employee.full_name
      }
    }

    async handleMeetingUpdatedClicked () {
      this.isMeetingBeingUpdated = true

      //* Set the timestamp as the server is configured to convert the date and time from timestamp
      this.meetingCopy.meeting_date = this.meetingDateCopy.getTime()
      this.meetingCopy.meeting_time = this.meetingDateCopy.getTime()
      this.meetingCopy.meeting_completed = this.isMeetingCompleted

      //* If the person attending the meeting is changed
      const conductedByChanged = this.meeting.employee.employee_id !== this.meetingAttendedBy.employee_id

      let { isDifferent, deltaObject } = this.getObjectDifference(this.meeting, this.meetingCopy)
      if (!isDifferent && !conductedByChanged) {
        return
      }

      deltaObject = { ...deltaObject, meeting_attended_by: this.meetingAttendedBy.employee_id }

      const isUpdated = await this.updateAMeeting({ prospectId: this.prospectId, meetingId: this.meeting.meeting_id, meeting: deltaObject })
      if (isUpdated) {
        this.$buefy.snackbar.open(`Meeting with Id: ${this.meeting.meeting_id} updated successfully`)
      } else {
        this.$buefy.snackbar.open({
          duration: 15000,
          message: `Meeting with Id: ${this.meeting.meeting_id} updated failed`,
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'Retry',
          queue: false,
          onAction: () => {
            //* Try resubmitting the form
            this.handleMeetingUpdatedClicked()
          }
        })
      }
      this.isMeetingBeingUpdated = false
    }

    async handleKeyUpForEmployeeListInput () {
      const response = await network().get(`/employee?search=${this.meetingAttendedBy.employee_name}`)
      this.dataPlaceholderForEmployeeList = response.data.data
    }
}
