import Util from '@/mixins/Util'
import network from '@/services/Network'
import { ProspectResponse } from '@/types/Prospect'
import { Component, Watch, Mixins } from 'vue-property-decorator'

@Component({
  name: 'CreateNewTownForm'
})
export default class CreateNewTownForm extends Mixins(Util) {
    private open = true;

    private isLoading = false;
    private allCountries: Array<ProspectResponse.Country> = [];
    private selectedCountryObject: ProspectResponse.Country = {} as ProspectResponse.Country;
    private allCities: Array<ProspectResponse.City> = [];
    private selectedCityObject: ProspectResponse.City = {} as ProspectResponse.City;

    private dataPlaceholderForAddressAutocomplete = [];
    private isFetchingAddressAutocomplete = false;
    private townName = '';

    // @ts-ignore: Cannot find name
    // private service: any = new google.maps.places.AutocompleteService();

    created () {
      this.getAllCountries()
      this.getAllCities()
    }

    // handleKeyUpForTownNameInput() {
    //     if (this.townName.length > 4) {
    //         this.isFetchingAddressAutocomplete = true;
    //         this.service.getQueryPredictions({ input: this.townName.length }, (predictions: any, status: any) => {
    //             console.log(predictions)
    //             this.dataPlaceholderForAddressAutocomplete = predictions;
    //         })
    //     }
    //     this.isFetchingAddressAutocomplete = false;
    // }

    async createNewTownHandler () {
      this.isLoading = true
      if (!this.townName || this.townName.length <= 4) {
        return
      }

      const payload = {
        town_name: this.townName,
        city: this.selectedCityObject
      }

      try {
        const response: any = await network().post('/address/town', payload)
        if (response.data.error) {
          this.$buefy.snackbar.open({
            duration: 15000,
            message: `${response.data.error}`,
            type: 'is-danger',
            position: 'is-bottom-left',
            actionText: 'Retry',
            queue: false,
            onAction: () => {
              //* Try resubmitting the form
              this.createNewTownHandler()
            }
          })
        } else {
          this.$buefy.snackbar.open(`Town ${this.townName} created with Id: ${response.data.data.town_id}`)
          this.$emit('createNewTownFormClosed')
          this.open = false
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          duration: 15000,
          message: `Town name ${this.townName} already exists`,
          type: 'is-danger',
          position: 'is-bottom-left',
          queue: false
        })
        this.$emit('createNewTownFormClosed')
        this.open = false
      } finally {
        this.isLoading = true
      }
    }

    @Watch('townName')
    townNameChanged () {
      this.townName = Util.titleSize(this.townName)
    }

    async getAllCountries () {
      const response = await network().get('/address/country')
      this.allCountries = response.data.data
      this.selectedCountryObject = this.allCountries[0]
    }

    async getAllCities () {
      const response = await network().get('/address/city')
      this.allCities = response.data.data
      this.selectedCityObject = this.allCities[0]
    }
}
