













































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ProspectCall } from '../../../../types/ProspectCall'

@Component({
  name: 'ProspectCallDetails'
})
export default class MeetingDetails extends Vue {
  @Prop({ required: true }) prospectCall!: ProspectCall.Content;

  private open = true;
}
