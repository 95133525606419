import Util from '@/mixins/Util'
import network from '@/services/Network'
import { NewMeetingType } from '@/types/Meeting'
import { ProspectResponse } from '@/types/Prospect'
import { ProspectCall } from '@/types/ProspectCall'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DateTime } from 'luxon'

const MeetingModule = namespace('MeetingModule')
const ProspectModule = namespace('ProspectModule')

@Component({
  name: 'CreateMeetingForm'
})
export default class CreateMeetingForm extends Vue {
    @Prop({ required: true }) prospectId!: number;

    @Prop({ required: false }) prospectCallResponse!: ProspectCall.CallResponse;

    @ProspectModule.Getter
    private getProspectDetail!: ProspectResponse.Content;

    private open = true;
    private meetingType = ['In House', 'Out House'];
    private inHouseMeetingAddress = ['Lahore Office', 'Islamabad Office'];
    private newMeeting = { meeting_type: this.meetingType[0], meeting_address: this.inHouseMeetingAddress[0], meeting_completed: false } as NewMeetingType;

    private isLoading = false;
    private meetingConductedBy = 'Yes';
    private schedulingMeetingAfterACall = false;
    private schedulingMeetingAfterACallValue = 0;
    private isFetchingEmployeeListComplete = false;
    private dataPlaceholderForEmployeeList = new Array<{ employee_id: number, employee_name: string }>();
    private meetingAttendedBy: { employee_id: number, employee_name: string } = {} as any;
    private meetingDateTime = new Date();

    created () {
      this.meetingAttendedBy = {
        employee_id: this.getProspectDetail.employee.employee_id,
        employee_name: this.getProspectDetail.employee.full_name
      }
    }

    get getProspectCallResponseId (): number {
      return this.prospectCallResponse.call_response_id
    }

    @MeetingModule.Action
    private createNewMeeting!: (data: { meeting: NewMeetingType, prospect_id: number }) => Promise<any>;

    @Watch('newMeeting.meeting_address')
    meetingAddressChanged () {
      this.newMeeting.meeting_address = Util.titleSize(this.newMeeting.meeting_address)
    }

    async createNewMeetingHandler () {
      this.isLoading = true
      this.newMeeting.meeting_time = DateTime.fromJSDate(this.meetingDateTime).toSQLTime({ includeOffset: false, includeZone: false }).split('.')[0]
      this.newMeeting.meeting_date = DateTime.fromJSDate(this.meetingDateTime).toSQLDate()
      this.newMeeting.meeting_attended_by = this.meetingAttendedBy.employee_id

      if (this.schedulingMeetingAfterACall) {
        this.newMeeting.call_response_id = this.schedulingMeetingAfterACallValue as number
      } else if (this.getProspectCallResponseId) {
        this.newMeeting.call_response_id = this.getProspectCallResponseId
      } else {
        delete this.newMeeting.call_response_id
      }

      const response = await this.createNewMeeting({ meeting: this.newMeeting, prospect_id: this.prospectId })

      if (response.error) {
        this.$buefy.snackbar.open({
          duration: 10000,
          message: `${response.error}`,
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'Retry',
          queue: false,
          onAction: () => {
            //* Try resubmitting the form
            this.createNewMeeting({ meeting: this.newMeeting, prospect_id: this.prospectId })
          }
        })
      } else {
        this.$buefy.snackbar.open(`Meeting created with Id: ${response.meeting_id}`)
        this.newMeeting = {} as NewMeetingType
        this.$emit('createNewMeetingFormClosed')
        this.$emit('createNewMeetingFormClosedAfterCallResponse')
        this.open = false
      }
      this.isLoading = false
    }

    async handleKeyUpForEmployeeListInput () {
      const response = await network().get(`/employee?search=${this.meetingAttendedBy.employee_name}`)
      this.dataPlaceholderForEmployeeList = response.data.data
    }
}
