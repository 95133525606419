import { MeetingType } from '@/types/Meeting'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MeetingDetails from './MeetingDetails.vue'

const MeetingModule = namespace('MeetingModule')

@Component({
  name: 'MeetingList',
  components: {
    MeetingDetails
  }
})
export default class MeetingList extends Vue {
    @Prop({ required: true }) prospectId!: number;

    @MeetingModule.Action
    private fetchMeetingList!: (prospect: { prospect_id: number }) => Promise<void>;

    @MeetingModule.Getter
    private getMeetings!: MeetingType.Content[];

    @MeetingModule.Getter
    private getIsLoading!: boolean;

    private selected = {} as MeetingType.Content;

    async created () {
      this.fetchMeetingList({ prospect_id: this.prospectId })
    }

    handleTableRowDoubleClick (): void {
      this.$buefy.modal.open({
        parent: this,
        component: MeetingDetails,
        hasModalCard: true,
        trapFocus: true,
        props: { meeting: this.selected, prospectId: this.prospectId }
      })
    }

    addRowClass (row: any, index: number) {
      if (!row.meeting_completed) {
        return 'has-background-warning'
      }
    }
}
