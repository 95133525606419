import network from '@/services/Network'
import { NewFollowupType } from '@/types/Followup'
import { ProspectCall } from '@/types/ProspectCall'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

@Component({
    name: 'CreateFollowupForm'
})
export default class CreateFollowupForm extends Vue {
    @Prop({ required: true }) prospectId!: number;
    @Prop({ required: false }) prospectCallResponse!: ProspectCall.CallResponse;

    private open = true;
    private isLoading = false;
    private newFollowup = { follow_up_date: new Date() } as NewFollowupType;

    async createNewFollowupHandler() {
        this.isLoading = true
        this.newFollowup.follow_up_time = DateTime.fromJSDate(this.newFollowup.follow_up_date).toSQLTime({ includeOffset: false, includeZone: false }).split('.')[0]

        const response = await network().post(`/followup/${this.prospectId}`, this.newFollowup)

        if (response.status !== 200) {
            this.$buefy.snackbar.open({
                duration: 10000,
                message: `${response.data.error}`,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Retry',
                queue: false,
                onAction: () => {
                    //* Try resubmitting the form
                    this.createNewFollowupHandler()
                }
            })
        } else {
            this.$buefy.snackbar.open(`Follow Up created with Id: ${response.data.data.follow_up_id}`)
            this.newFollowup = {} as NewFollowupType
            this.$emit('createNewFollowupFormClosed')
            this.open = false
        }
        this.isLoading = false
    }
}
