import Util from '@/mixins/Util'
import network from '@/services/Network'
import { NewCallResponse, ResponseStatus } from '@/types/CallResponse'
import { NewMeetingType } from '@/types/Meeting'
import { ProspectResponse } from '@/types/Prospect'
import { NewProspectCall, ProspectCallResult, ProspectCallStatus } from '@/types/ProspectCall'
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ProspectModule = namespace('ProspectModule')

@Component({
    name: 'CreateProspectCallForm'
})
export default class CreateProspectCallForm extends Mixins(Util) {
    @Prop({ required: true }) prospectId!: number;

    private open = true;
    private activeStep = 0;
    private meetingType = ['In House', 'Out House'];
    private newProspectCall = { dialed_date: new Date(), status: true, duration: 1 } as NewProspectCall;
    private newCallResponse = {} as NewCallResponse;
    private newProspectCallResult = ['None', 'Meeting', 'Call Me Later'];
    private callResponseStatus = ['Meeting or Call not done', 'Meeting or Call done', 'Call Cancelled']

    private isLoading = false;
    private callResponseScheduledFor = false;

    @ProspectModule.Getter
    private getProspectDetail!: ProspectResponse.Content;

    created() {
        const tempProspectCopy = { ...this.getProspectDetail }
        this.newProspectCall.number_dialed = tempProspectCopy.mobile_no
        this.newProspectCall.call_result = this.newProspectCallResult[this.newProspectCallResult.length - 1]
        this.newCallResponse.response_status = this.callResponseStatus[0]
    }

    async createNewProspectCallHandler() {
        // this.isLoading = true;
        // @ts-ignore
        this.newProspectCall.dialed_time = this.newProspectCall.dialed_date.getTime()
        this.newProspectCall.status = this.newProspectCall.status ? 1 : 0
        this.newProspectCall.duration = new String(this.newProspectCall.duration).toString()

        //* Assign the prospect call response based on prospect call
        this.newCallResponse.scheduled_date = this.newProspectCall.dialed_date
        this.newCallResponse.scheduled_time = this.newProspectCall.dialed_time
        this.newCallResponse.scheduled_for = this.newProspectCall.call_result ? 1 : 2

        this.newProspectCall.call_result = ProspectCallResult[this.newProspectCall.call_result as keyof typeof ProspectCallResult]
        //* Get the response status based on the prospect call result
        // ? Why? because this is redundant to ask for same values
        this.newCallResponse.response_status = this.newProspectCall.call_result
        try {
            const response = await network().post(`/prospect-call/${this.prospectId}`, { ...this.newProspectCall, call_response: this.newCallResponse })

            if (response.status !== 200) {
                this.$buefy.snackbar.open({
                    duration: 15000,
                    message: `Could not create Prospect Call for prospect Id ${this.prospectId}`,
                    type: 'is-danger',
                    position: 'is-bottom-left',
                    actionText: 'Retry',
                    queue: false,
                    onAction: () => {
                        //* Try resubmitting the form
                        this.createNewProspectCallHandler()
                    }
                })
            } else {
                this.$buefy.snackbar.open(`Prospect Call created with Id: ${response.data.data.prospect_call_id}`)
                this.newProspectCall = { dialed_date: new Date(), status: true } as NewProspectCall
                this.newCallResponse = {} as NewCallResponse
                this.$emit('createNewProspectCallFormClosed')
                this.open = false
                //* If the call is result is meeting emit meeting event and open meeting form else open followup form
                //* 1 is meeting and 2 is followup
                if (response.data.data.call_result === 1) {
                    this.$emit('createNewMeeting', response.data.data)
                } else if (response.data.data.call_result === 2) {
                    this.$emit('createNewFollowup', response.data.data)
                }
            }
        } catch (error) {
            this.$buefy.snackbar.open({
                duration: 15000,
                message: `Could not create Prospect Call for prospect Id ${this.prospectId}`,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Retry',
                queue: false,
                onAction: () => {
                    //* Try resubmitting the form
                    this.createNewProspectCallHandler()
                }
            })
        } finally {
            this.isLoading = false
        }
    }
}
