import Util from '@/mixins/Util'
import network from '@/services/Network'
import { ProspectResponse, RiskTakingAttitudeCategories, ProspectCurrentStatus, ProspectType, ProfileStatus, MeansOfKnowing } from '@/types/Prospect'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DateTime } from 'luxon'
import CreateNewTownForm from './address/CreateNewTownForm.vue'

const ProspectModule = namespace('ProspectModule')

@Component({
    name: 'ProspectFormDetails',
    components: {
        CreateNewTownForm
    }
})
export default class ProspectFormDetails extends Mixins(Util) {
    @Prop({ required: true }) prospectId!: number;

    @ProspectModule.Action
    private getAProspect!: (prospectId: number) => Promise<void>;

    @ProspectModule.Action
    private updateAProspect!: (prospect: any) => Promise<boolean>;

    @ProspectModule.Getter
    private getProspectDetail!: ProspectResponse.Content;

    @ProspectModule.Getter
    private getIsLoading!: boolean;

    private isLoading = false;
    private currentStatus: Array<string> = [];
    private prospectType: Array<string> = [];
    private profileStatus: Array<string> = [];
    private riskTakingAttitude: Array<string> = [];
    private meansOfKnowing: Array<string> = [];
    private expectedClosingDate = new Date();
    private pageInitializedOnLoad = false;

    private prospectDetailCopy: ProspectResponse.Content = {} as ProspectResponse.Content;

    //* Address related variables and data
    private isFetchingAddressAutocomplete = false;
    private dataPlaceholderForTownAutocomplete: ProspectResponse.Town[] = [];
    private allCountries: Array<ProspectResponse.Country> = [];
    private selectedCountryObject: ProspectResponse.Country = {} as ProspectResponse.Country;
    private allCities: Array<ProspectResponse.City> = [];
    private selectedCityObject: ProspectResponse.City = {} as ProspectResponse.City;

    private isTownInputFocused = false;
    private newTownFormOpened = false;

    async created() {
        this.riskTakingAttitude = this.enumToStringValues(RiskTakingAttitudeCategories)
        this.currentStatus = this.enumToStringValues(ProspectCurrentStatus)
        this.prospectType = this.enumToStringValues(ProspectType)
        this.profileStatus = this.enumToStringValues(ProfileStatus)
        this.meansOfKnowing = this.enumToStringValues(MeansOfKnowing)

        this.prospectDetailCopy.employee = {} as ProspectResponse.Employee
        this.prospectDetailCopy.address = { town: { city: { country: {} } } } as ProspectResponse.Address
        await this.getAProspect(this.prospectId)

        if (!this.getProspectDetail.address || this.getProspectDetail.address === undefined) {
            //* Address is not set, setup for possible new address entry
            const newAddressObject = {
                house_no: '',
                sector: '',
                landmark: '',
                town: {
                    town_name: '',
                    city: {
                        city_name: '',
                        country: {
                            country_name: ''
                        }
                    }
                }
            } as ProspectResponse.Address
            this.prospectDetailCopy = {
                ...this.getProspectDetail,
                address: { ...newAddressObject }
            }
        } else {
            // //* Make a copy
            this.prospectDetailCopy = {
                ...this.getProspectDetail,
                address: {
                    ...this.getProspectDetail.address,
                    town: {
                        ...this.getProspectDetail.address.town,
                        city: {
                            ...this.getProspectDetail.address.town.city,
                            country: { ...this.getProspectDetail.address.town.city.country }
                        }
                    }
                }
            }
        }
        if (this.prospectDetailCopy.closing_date) {
            this.expectedClosingDate = DateTime.fromSQL(this.prospectDetailCopy.closing_date).toJSDate()
        }
    }

    @Watch('prospectDetailCopy.profile_status')
    profileStatusChanged() {
        //* If the page is loaded and then there is a change in status the initialize the prompt
        if (this.prospectDetailCopy.profile_status === ProfileStatus[3] && this.pageInitializedOnLoad) {
            this.$buefy.dialog.confirm({
                message: `This prospect/lead <strong>${this.prospectDetailCopy.prospect_id}</strong> with name <strong>${this.prospectDetailCopy.prospect_name}</strong> has reached Stage 3 and one step away from closing. You have to provide the <strong>Final Closing Margin</strong> & <strong>Closing Date</strong>.`,
                trapFocus: true,
                onConfirm: () => {
                    this.$buefy.toast.open('You acknowledge terms to provide the information.')
                },
                onCancel: () => {
                    this.prospectDetailCopy.profile_status = ProfileStatus[2]
                }
            })
        }
        this.pageInitializedOnLoad = true
    }

    async handleKeyUpForTownInput() {
        // @ts-ignore
        if (this.prospectDetailCopy.address.town.town_name.length > 1) {
            this.isFetchingAddressAutocomplete = true
            const response = await network().get(`/address/town?search=${this.prospectDetailCopy.address?.town.town_name}`)
            this.dataPlaceholderForTownAutocomplete = response.data.data.content
            this.isFetchingAddressAutocomplete = false
        }
    }

    async getAllCountries() {
        const response = await network().get('/address/country')
        this.allCountries = response.data.data
        this.selectedCountryObject = this.allCountries[0]
    }

    async getAllCities() {
        const response = await network().get('/address/city')
        this.allCities = response.data.data
        this.selectedCityObject = this.allCities[0]
    }

    private async getProspectDifference(baseProspect: any, changedProspect: any) {
        const diffBuffer = new Object()
        const keys = Object.keys(baseProspect)
        let shouldUpdate = false

        keys.splice(keys.indexOf('address', 1))
        keys.forEach(key => {
            if (baseProspect[key] !== changedProspect[key]) {
                shouldUpdate = true
                if (key === 'projection') {
                    changedProspect[key] = parseFloat(changedProspect[key].toFixed(2))
                }
                Object.assign(diffBuffer, { [key]: changedProspect[key] })
            }
        })

        //* Handle the nested address object by converting to string and then do a comparison
        if (JSON.stringify(baseProspect.address) !== JSON.stringify(changedProspect.address)) {
            if (changedProspect.address.town.town_id) {
                shouldUpdate = true
                Object.assign(diffBuffer, { address: changedProspect.address })
            }
        }

        if (!shouldUpdate) return
        Object.assign(diffBuffer, { prospect_id: baseProspect.prospect_id })
        // @ts-ignore
        delete diffBuffer.employee
        if (await this.updateAProspect(diffBuffer)) {
            this.$buefy.snackbar.open('Prospect updated successfully')
        } else {
            this.$buefy.snackbar.open({
                duration: 15000,
                message: 'Prospect update failed. Please try refreshing the browser and try again.',
                type: 'is-danger',
                position: 'is-bottom-left',
                queue: false
            })
        }
    }

    updateProspect() {
        this.isLoading = true
        if (this.prospectDetailCopy.profile_status === ProfileStatus[3]) {
            this.prospectDetailCopy.closing_date = DateTime.fromJSDate(this.expectedClosingDate).toSQLDate()
        }
        this.getProspectDifference(this.getProspectDetail, this.prospectDetailCopy)
        this.isLoading = false
    }
}
