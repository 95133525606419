import { render, staticRenderFns } from "./FollowupList.vue?vue&type=template&id=c2e1d6be&scoped=true&"
import script from "./FollowupList.vue?vue&type=script&lang=ts&"
export * from "./FollowupList.vue?vue&type=script&lang=ts&"
import style0 from "./FollowupList.vue?vue&type=style&index=0&id=c2e1d6be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e1d6be",
  null
  
)

export default component.exports