





























































































































































import { Component, Vue } from "vue-property-decorator";
import Navbar from "../../components/layout/Navbar.vue";
import ProspectFormDetails from "../../components/dashboard/prospect/ProspectFormDetails.vue";
import ProspectChangeHistory from "../../components/dashboard/prospect/ProspectChangeHistory.vue";
import MeetingList from "../../components/dashboard/prospect/meeting/MeetingList.vue";
import CreateMeetingForm from "../../components/dashboard/prospect/meeting/CreateMeetingForm.vue";
import CreateProspectCallForm from "../../components/dashboard/prospect/call/CreateProspectCallForm.vue";
import FollowupList from "../../components/dashboard/prospect/followup/FollowupList.vue";
import CreateFollowupForm from "../../components/dashboard/prospect/followup/CreateFollowupForm.vue";
import ProspectCallList from "../../components/dashboard/prospect/call/ProspectCallList.vue";
import { ProspectCall } from "../../types/ProspectCall";
import { namespace } from "vuex-class";
import { DialogProgrammatic as Dialog } from "buefy";

const MeetingModule = namespace("MeetingModule");

@Component({
  components: {
    Navbar,
    ProspectFormDetails,
    ProspectChangeHistory,
    MeetingList,
    CreateMeetingForm,
    CreateProspectCallForm,
    ProspectCallList,
    CreateFollowupForm,
    FollowupList,
  },
})
export default class ProspectDetail extends Vue {
  private prospectId = 0;
  private openCreateNewMeetingForm = false;
  private openCreateNewProspectCallForm = false;
  private openCreateNewFollowupForm = false;

  private callResponseObjectAfterCall: ProspectCall.CallResponse =
    {} as ProspectCall.CallResponse;

  @MeetingModule.Getter
  private getIncompleteMeeting!: any;

  created() {
    this.prospectId = parseInt(this.$route.params.prospectId);
  }

  private createMeetingHandler() {
    if (!this.getIncompleteMeeting) {
      this.openCreateNewMeetingForm = true;
      return;
    }
    Dialog.alert({
      title: "New Meeting Error",
      message: `New meeting cannot be created becasue <strong>Meeting#${this.getIncompleteMeeting.meeting_id}</strong> is not completed. </br> Please complete the meeting and then proceed to create a new meeting.`,
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa",
      ariaRole: "alertdialog",
      ariaModal: true,
    });
  }

  private handleCreateNewMeetingAfterProspectCall(
    prospectCallResponse: ProspectCall.Content
  ) {
    if (!this.getIncompleteMeeting) {
      this.callResponseObjectAfterCall = prospectCallResponse.call_response;
      this.openCreateNewMeetingForm = true;
      return;
    }
    Dialog.alert({
      title: "New Meeting Error",
      message: `New meeting cannot be created becasue <strong>Meeting#${this.getIncompleteMeeting.meeting_id}</strong> is not completed. </br> Please complete the meeting and then proceed to create a new meeting.`,
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa",
      ariaRole: "alertdialog",
      ariaModal: true,
    });
  }
}
