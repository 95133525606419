


























































































































import FollowupDetails from './FollowupDetails.src'
export default FollowupDetails
