import Util from '@/mixins/Util'
import { ProspectHistoryResponse, ProspectResponse } from '@/types/Prospect'
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ProspectModule = namespace('ProspectModule')
const Auth = namespace('AuthModule')

@Component({
  name: 'ProspectChangeHistory',
  filters: {
    snakeCaseToSpaceCase (value: string) {
      return value.split('_').map(str => Util.titleSize(str)).join(' ')
    }
  }
})
export default class ProspectChangeHistory extends Vue {
    @Prop({ required: true }) prospectId!: number;

    @ProspectModule.Action
    private getAProspectHistory!: (prospectId: number) => Promise<void>;

    @ProspectModule.Getter
    private getProspectHistoryContent!: ProspectHistoryResponse.Content[];

    @ProspectModule.Getter
    private getProspectDetail!: ProspectResponse.Content;

    @Auth.Getter
    private userDetail!: any;

    async created () {
      this.getAProspectHistory(this.prospectId)
    }
}
