import network from '@/services/Network'
import { FollowupType } from '@/types/Followup'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'

@Component({
  name: 'FollowupDetails'
})
export default class FollowupDetails extends Vue {
    @Prop({ required: true }) prospectId!: number;
    @Prop({ required: true }) followupId!: number;

    private open = true;
    private followup = { prospect: {} } as FollowupType.Content;
    private followupDateTimeCopy = new Date();
    private followUpExpectancy = ['Low', 'Medium', 'High'];
    private isFollowUpCompleted = false;
    private isLoading = false;

    async created () {
      const response = await network().get(`/followup/${this.prospectId}/${this.followupId}`)
      this.followup = response.data.data

      const date = new Date(this.followup.follow_up_date)
      const completeDate = date.toUTCString().replace(/\d{2}:\d{2}:\d{2}/gm, this.followup.follow_up_time as string)
      this.followupDateTimeCopy = new Date(Date.parse(completeDate + '+0500 (Pakistan Standard Time)'))

      this.isFollowUpCompleted = this.followup.followup_completed
    }

    async followupUpdateHandle () {
      this.isLoading = true
      this.followup.follow_up_date = DateTime.fromJSDate(this.followupDateTimeCopy).toSQLDate()
      this.followup.follow_up_time = DateTime.fromJSDate(this.followupDateTimeCopy).toSQLTime({ includeOffset: false, includeZone: false }).split('.')[0]
      this.followup.followup_completed = this.isFollowUpCompleted

      try {
        const response = await network().patch(`/followup/${this.prospectId}/${this.followupId}`, this.followup)
        this.$buefy.snackbar.open(`Followup with Id: ${this.followup.follow_up_id} updated successfully`)
      } catch (error) {
        this.$buefy.snackbar.open({
          duration: 15000,
          message: `Followup with Id: ${this.followup.follow_up_id} updated failed`,
          type: 'is-danger',
          position: 'is-bottom-left',
          actionText: 'Retry',
          queue: false,
          onAction: () => {
            //* Try resubmitting the form
            this.followupUpdateHandle()
          }
        })
      }

      this.isLoading = false
    }
}
