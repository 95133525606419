import network from '@/services/Network'
import { FollowupType } from '@/types/Followup'
import { Component, Vue, Prop } from 'vue-property-decorator'

import FollowupDetails from './FollowupDetails.vue'

@Component({
  name: 'FollowupList',
  components: {
    FollowupDetails
  }
})
export default class FollowupList extends Vue {
    @Prop({ required: true }) prospectId!: number;

    private isLoading = false;
    private followupResponseObject = { data: {} } as FollowupType.RootObject;
    private selected = {} as FollowupType.Content;

    async getFollowups (): Promise<void> {
      // todo check for errors
      const response = await network().get(`/followup/${this.prospectId}`)
      this.followupResponseObject = response.data
    }

    async created () {
      this.isLoading = true
      await this.getFollowups()
      this.isLoading = false
    }

    get followups (): FollowupType.Content[] {
      return this.followupResponseObject.data.content ? this.followupResponseObject.data.content : []
    }

    handleTableRowDoubleClick () {
      this.$buefy.modal.open({
        parent: this,
        component: FollowupDetails,
        hasModalCard: true,
        trapFocus: true,
        props: { prospectId: this.prospectId, followupId: this.selected.follow_up_id }
      })
    }

    addRowClass (row: any, index: number) {
      if (!row.followup_completed) {
        return 'has-background-warning'
      }
    }
}
