var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"width":1000,"scroll":"keep","on-cancel":function () {
        _vm.open = false;
        this$1.$emit('createNewFollowupFormClosed');
      }},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('fieldset',[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" New Followup For Prospect # "+_vm._s(_vm.prospectId)+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('center',[_c('b-field',{attrs:{"label":"Select Followup Date & Time"}},[_c('b-datetimepicker',{attrs:{"inline":""},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('b-button',{attrs:{"label":"Now","type":"is-primary","icon-left":"clock"},on:{"click":function($event){_vm.newFollowup.follow_up_date = new Date()}}})]},proxy:true},{key:"right",fn:function(){return [_c('b-button',{attrs:{"label":"Clear","type":"is-danger","icon-left":"times","outlined":""},on:{"click":function($event){_vm.newFollowup.follow_up_date = null}}})]},proxy:true}]),model:{value:(_vm.newFollowup.follow_up_date),callback:function ($$v) {_vm.$set(_vm.newFollowup, "follow_up_date", $$v)},expression:"newFollowup.follow_up_date"}})],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"field is-grouped is-grouped-right"},[_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"button","icon-left":"plus"},on:{"click":_vm.createNewFollowupHandler}},[_vm._v(" Create ")])]),_c('p',{staticClass:"control"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function () {
                    _vm.open = false;
                    this$1.$emit('createNewFollowupFormClosed');
                  }}},[_vm._v(" Close ")])])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }