import network from '@/services/Network'
import { ProspectCall } from '@/types/ProspectCall'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ProspectCallDetails from '@/components/dashboard/prospect/call/ProspectCallDetails.vue'

@Component({
  name: 'ProspectCallList',
  components: {
    ProspectCallDetails
  }
})
export default class ProspectCallList extends Vue {
    @Prop({ required: true }) prospectId!: number;

    private isLoading = true;
    private prospectCalls: ProspectCall.Content[] = [];
    private selected = {} as ProspectCall.Content;

    async created () {
      const response = await network().get(`/prospect-call/${this.prospectId}`)
      this.prospectCalls = response.data.data.content as Array<ProspectCall.Content>
      this.isLoading = false
    }

    handleTableRowDoubleClick (): void {
      this.$buefy.modal.open({
        parent: this,
        component: ProspectCallDetails,
        hasModalCard: true,
        trapFocus: true,
        props: { prospectCall: this.selected }
      })
    }
}
