export interface NewProspectCall {
    dialed_date: number | Date;
    dialed_time: number;
    number_dialed: string;
    duration: string | number;
    call_result: number | string;
    call_quality: number;
    status: number | boolean;
}

export enum ProspectCallStatus {
    'Not Responded',
    'Responded'
}

export enum ProspectCallResult {
    'None',
    'Meeting',
    'Call Me Later'
}

export namespace ProspectCall {

    export interface CallResponse {
        call_response_id: number;
        scheduled_date: number;
        scheduled_time: string;
        scheduled_for: number;
        response_status: number;
        cancellation_remarks?: any;
    }

    export interface Content {
        prospect_call_id: number;
        dialed_date: number;
        dialed_time: string;
        number_dialed: string;
        status: number;
        remarks?: any;
        duration: string;
        call_result: number;
        call_quality: number;
        call_response: CallResponse;
    }

    export interface Sort {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Pageable {
        sort: Sort;
        pageNumber: number;
        pageSize: number;
        offset: number;
        paged: boolean;
        unpaged: boolean;
    }

    export interface Sort2 {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Data {
        content: Content[];
        pageable: Pageable;
        totalPages: number;
        totalElements: number;
        last: boolean;
        first: boolean;
        sort: Sort2;
        number: number;
        numberOfElements: number;
        size: number;
        empty: boolean;
    }

    export interface RootObject {
        error?: any;
        timestamp: string;
        status: string;
        data: Data;
    }

}
